import { ApolloError } from "@apollo/client/core";
import type { GraphQLErrors } from "@apollo/client/errors";

import { GraphQLErrorCode } from "./errors";

/**
 * Takes in an object and returns error messages if error is an ApolloError. If
 * getting a user facing error, consider using `getUserFacingErrorMessage` or
 * `getUserFacingErroMessages` instead.
 */
export function getErrorMessagesWithCode(error: any, code: string): string[] {
  if (!(error instanceof ApolloError)) {
    return [];
  }
  return getErrorMessagesFromErrors(error.graphQLErrors ?? [], code);
}

export function getErrorMessagesFromErrors(
  errors: GraphQLErrors,
  code: string,
): string[] {
  return errors.reduce<string[]>((errorMessages, err) => {
    if (err.extensions?.code === code) {
      errorMessages.push(err.message);
    }
    return errorMessages;
  }, []);
}

/**
 * Takes in an object an returns user facing error messages if error is an
 * ApolloError. Use this only if you have a multiple mutations/queries and
 * expect more than one user facing error. Otherwise, use
 * `getUserFacingErrorMessage`
 */
export function getUserFacingErrorMessages(error: any): string[] {
  return getErrorMessagesWithCode(error, GraphQLErrorCode.USER_FACING_ERROR);
}

/**
 * Takes in an object an returns user facing error messages if error is an
 * ApolloError.
 */
export function getUserFacingErrorMessage(error: any): string | undefined {
  return getUserFacingErrorMessages(error)[0];
}

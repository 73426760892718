import { partition } from "lodash";

import { calciteTypeToChartDataType } from "../chart/hql/chartHqlUtils.js";
import { ChartDataType } from "../chart/types.js";
import { ColumnFilter } from "../display-table/filterTypes.js";
import { CalciteType, HqlAggregationFunction } from "../hql/types.js";
import { SemanticDatasetName } from "../idTypeBrands.js";
import type { HexSLTypes } from "../index.js";
import {
  semanticDatasetTitleString,
  semanticDimensionTitleString,
  semanticMeasureTitleString,
} from "../semantic-layer/semanticTitleUtils.js";
import { concatRespectingCase } from "../utils/stringUtils.js";

import {
  CHART_FOLD_KEYS,
  COUNT_STAR_ARG,
  COUNT_STAR_LABEL,
} from "./exploreConstants.js";
import { ExploreInputField } from "./exploreDndTypes.js";
import { hqlAggToPivotAgg } from "./explorePivotUtils.js";
import { HexSLTypeMapping, NumericMeasureAggTypes } from "./exploreUtils.js";
import {
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
} from "./semanticTypes.js";
import {
  ExploreField,
  ExploreFieldType,
  getExploreTimeUnitName,
} from "./types.js";

/**
 * A field is a aggregated if (1) it is a measure or (2) it is a
 * dimension/column that has an aggregation set
 */
export function isAggregatedField(field: ExploreField): boolean {
  if (field.fieldType === ExploreFieldType.MEASURE) {
    return true;
  }
  return field.aggregation != null;
}

export function getFieldActiveScaleType(field: ExploreField): ChartDataType {
  return field.scaleType ?? calciteTypeToChartDataType(field.dataType);
}

export const BASE_COUNT_STAR_FIELD: Omit<SemanticAwareColumn, "queryPath"> = {
  columnId: COUNT_STAR_ARG,
  columnName: COUNT_STAR_LABEL,
  columnType: "NUMBER",
  fieldType: ExploreFieldType.MEASURE,
  description: undefined,
};

export const getCountStarField = (
  queryPath: SemanticDatasetName[],
): SemanticAwareColumn => ({
  ...BASE_COUNT_STAR_FIELD,
  queryPath,
});

export const getChartFoldKeysField = (
  label: string | undefined,
): SemanticAwareColumn => ({
  columnId: CHART_FOLD_KEYS,
  columnName: label ?? "Columns",
  columnType: "STRING",
  fieldType: ExploreFieldType.DIMENSION,
  description: undefined,
  queryPath: [],
});

export const isCountStarOutputColumn = (columnId: string) => {
  return columnId.toLowerCase().endsWith(COUNT_STAR_ARG.toLowerCase());
};

interface PartialInputField {
  id: string;
  queryPath: SemanticDatasetName[] | undefined;
}

/**
 * Given an explore field, generates a unique column idenitfier for the field,
 * which includes the query path if it exists.
 */
export function generateColumnIdForField(
  field:
    | Pick<ExploreField, "value" | "queryPath">
    | PartialInputField
    | SemanticAwareColumn
    | ColumnFilter,
): string {
  const queryPath = field.queryPath;
  const value =
    "value" in field
      ? field.value
      : "columnId" in field
        ? field.columnId
        : "column" in field
          ? field.column
          : field.id;
  // the first item in the query path is always the base dataset
  // so having only 1 item in the query path doesn't point to any joins
  if (queryPath == null || queryPath.length === 0) {
    return value;
  }
  return [...queryPath, value].join(":");
}

/**
 * This generates a field name that is used to identify fields in the query
 * results. This is used across the viz types to keep column names consistent.
 */
export function getExploreFieldName(
  field: Pick<
    ExploreField,
    "aggregation" | "truncUnit" | "value" | "queryPath" | "fieldType"
  >,
): string {
  let value = generateColumnIdForField(field);

  if (field.fieldType === ExploreFieldType.MEASURE) {
    return value;
  }
  if (field.aggregation != null) {
    value = concatRespectingCase(
      value,
      // use pivot agg to stay in sync with the pivot column name
      `_${hqlAggToPivotAgg(field.aggregation) ?? field.aggregation}`,
      "append",
    );
  }
  if (field.truncUnit != null) {
    const timeUnitName = getExploreTimeUnitName(field.truncUnit, undefined);
    value = concatRespectingCase(value, `_${timeUnitName}`, "append");
  }

  return value;
}

export const EXPLORE_CHART_ONLY_AGGREGATIONS: Set<HqlAggregationFunction> =
  new Set(["StdDev", "StdDevPop", "Variance", "VariancePop"]);

export function partitionNestedTableFields(fields: ExploreField[]): {
  aggFields: ExploreField[];
  groupByFields: ExploreField[];
} {
  const [aggFields, groupByFields] = partition(
    fields,
    (field) => field.aggregation != null || field.fieldType === "MEASURE",
  );

  return {
    aggFields,
    groupByFields,
  };
}

export function calciteTypeisDatetimeType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "datetime";
}

export function calciteTypeisNumericType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "number";
}

export const measureToExploreInputField = (
  measure: HexSLTypes.Measure,
  queryPath: SemanticDatasetName[],
): SemanticAwareColumn => ({
  measure,
  columnName: semanticMeasureTitleString(measure),
  columnType: NumericMeasureAggTypes.guard(measure.type)
    ? "NUMBER"
    : HexSLTypeMapping[measure.type],
  columnId: measure.name,
  fieldType: ExploreFieldType.MEASURE,
  description: measure.description ?? undefined,
  queryPath,
});

export const dimensionToExploreInputField = (
  dimension: HexSLTypes.Dimension,
  queryPath: SemanticDatasetName[],
): SemanticAwareColumn => ({
  dimension,
  columnName: semanticDimensionTitleString(dimension),
  // The dimensions we pass in here _should_ always have a `type` since they aren't
  // from user-defined calcs (which is the only case where we elide the type).
  columnType: HexSLTypeMapping[dimension.type ?? "null"],
  columnId: dimension.name,
  fieldType: ExploreFieldType.DIMENSION,
  description: dimension.description ?? undefined,
  queryPath,
});

export const semanticDatasetToFieldGroup = ({
  dimensions,
  measures,
  name,
  queryPath,
  title,
}: {
  name: SemanticDatasetName;
  title: string | null;
  measures: HexSLTypes.Measure[];
  dimensions: HexSLTypes.Dimension[];
  queryPath: SemanticDatasetName[];
}): SemanticAwareFieldGroup => {
  return {
    name,
    title: semanticDatasetTitleString({ name, title }),
    type: "dataset",
    columns: dimensions.flatMap((dim) =>
      dim.hidden ? [] : [dimensionToExploreInputField(dim, queryPath)],
    ),
    measures: [
      getCountStarField(queryPath),
      ...measures.flatMap((measure) =>
        measure.hidden ? [] : [measureToExploreInputField(measure, queryPath)],
      ),
    ],
    children: [],
    queryPath: queryPath,
  };
};

/**
 * Temporary mapping utility until we can deprecate the `ExploreInputField`
 */
export const semanticAwareColumnToExploreInputField = (
  col: SemanticAwareColumn,
): ExploreInputField => {
  if (col.columnId === COUNT_STAR_ARG) {
    return {
      id: COUNT_STAR_ARG,
      name: COUNT_STAR_LABEL,
      type: col.columnType,
      fieldType: col.fieldType,
      queryPath: col.queryPath,
    };
  }
  return {
    id: col.columnId,
    name: col.columnName,
    type: col.columnType,
    fieldType: col.fieldType,
    queryPath: col.queryPath,
  };
};

export const exploreInputFieldToSemanticAwareColumn = (
  col: ExploreInputField,
): SemanticAwareColumn => {
  return {
    columnId: col.id,
    columnName: col.name,
    columnType: col.type,
    fieldType: col.fieldType,
    queryPath: col.queryPath,
    description: col.description,
  };
};

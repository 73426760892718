import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Draft, original } from "immer";
import { isEqual } from "lodash";

import {
  ChartExploreChannelManyPerSeries,
  ChartExploreChannelOnePerSeries,
  ChartExploreChannelTopLevel,
  ExploreChannel,
  ExploreChartConfig,
  ExploreDetailField,
  ExploreField,
  ExploreFieldId,
  ExploreFieldSimpleOptions,
  ExploreFieldType,
  ExploreSeriesId,
  ExploreSpec,
  ExploreTimeUnit,
  ExploreUserJoin,
  ExploreUserJoinColumn,
  ExploreUserJoinTable,
  ExploreViewType,
  PivotExploreChannel,
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
  calciteTypeisDatetimeType,
  calciteTypeisNumericType,
  defaultExploreAggregation,
  defaultExploreChartSeries,
  exploreSpecToPivotConfig,
  generateColumnIdForField,
  isHistogram,
  mapExploreFieldsToChartFields,
  mapExploreFieldsToPivotFields,
  maybeInvalidMappingReason,
  pivotAggToHqlAgg,
  pivotTruncUnitToExploreTruncUnit,
  updateExploreSpecFromChartDiffs,
} from "../";
import { dataFrameHeaderTypeToChartDataType } from "../../chart/chartDataTypeUtils.js";
import { ChartSpec, ChartTypeSelectorOption } from "../../chart/types.js";
import { assertNever } from "../../errors.js";
import { columnTypeToCalciteType } from "../../hql/types.js";
import {
  DisplayTableColumnId,
  SemanticDatasetName,
} from "../../idTypeBrands.js";
import { PivotGroupByField, PivotValueField } from "../../pivot/pivotTypes.js";
import { getDropValueForPivotSection as getPivotDropValue } from "../../pivot/pivotUiUtils.js";
import {
  SemanticDatasetStub,
  getJoinsToDelete,
} from "../../semantic-layer/semanticGraph.js";
import { PushdownSqlDialect } from "../../sql/dialects.js";
import { uuid } from "../../uuid.js";
import { getExploreSimpleType } from "../exploreAggregationUtils.js";
import { ExploreInputField } from "../exploreDndTypes.js";
import { truncationChannels } from "../exploreFieldMenuUtils.js";

// #region main explore state modification logic

const exploreSpecSlice = createSlice({
  name: "exploreSpec",
  initialState: {} as unknown as ExploreSpec,
  reducers: {
    setSpec(_state, { payload: spec }: PayloadAction<ExploreSpec>) {
      return spec;
    },
    addFieldAsNewSeries(
      state,
      {
        payload: { groupIdx, inputField },
      }: PayloadAction<{ groupIdx: number; inputField: ExploreInputField }>,
    ) {
      const newSeries = defaultExploreChartSeries("line");
      state.chartConfig.series.push(newSeries);

      state.fields.push({
        id: ExploreFieldId.check(uuid()),
        seriesId: newSeries.id,
        channel: "cross-axis",
        title:
          inputField.fieldType !== "COLUMN" && inputField.id !== inputField.name
            ? inputField.name
            : undefined,
        dataType: columnTypeToCalciteType(inputField.type),
        value: inputField.id,
        aggregation: defaultExploreAggregation(
          "cross-axis",
          inputField.type,
          inputField.fieldType,
        ),
        fieldType: inputField.fieldType,
        queryPath: inputField.queryPath,
      });

      while (state.chartConfig.seriesGroups.length <= groupIdx) {
        state.chartConfig.seriesGroups.push([]);
      }
      state.chartConfig.seriesGroups[groupIdx]!.push(newSeries.id);
    },
    /**
     * Adds the input field, auto-populating chart properties like channel and aggregation
     * and creating a new series if necessary.
     */
    autoAddField(
      state,
      {
        payload: { inputField },
      }: PayloadAction<{
        inputField: ExploreInputField;
      }>,
    ) {
      addDefaultSeriesIfNone(state);
      const firstSeries = state.chartConfig.series[0]!;
      const { fieldType } = inputField;
      const { aggregation, channel } = getAutoAddFieldDestination(
        state,
        inputField,
      );

      updateOrAddField(state, {
        id: inputField.fieldId ?? ExploreFieldId.check(uuid()),
        seriesId: firstSeries.id,
        channel,
        aggregation,
        title:
          fieldType !== "COLUMN" && inputField.id !== inputField.name
            ? inputField.name
            : undefined,
        dataType: columnTypeToCalciteType(inputField.type),
        value: inputField.id,
        fieldType: fieldType,
        queryPath: inputField.queryPath,
      });
      cleanUpOrphanedSeries(state);
    },
    addChartTopLevelField(
      state,
      {
        payload: { channel, inputField, options },
      }: PayloadAction<{
        channel: ChartExploreChannelTopLevel;
        inputField: ExploreInputField;
        options?: UpdateOrAddFieldOptions;
      }>,
    ) {
      addDefaultSeriesIfNone(state);

      // There can only be one of each type of top-level field,
      // so if we're adding a new one, remove any previous ones
      state.fields = state.fields.filter((f) => f.channel !== channel);

      const newFieldData: ExploreField = {
        id: inputField.fieldId ?? ExploreFieldId.check(uuid()),
        seriesId: state.chartConfig.series[0]!.id,
        channel,
        title:
          inputField.fieldType !== "COLUMN" && inputField.id !== inputField.name
            ? inputField.name
            : undefined,
        dataType: columnTypeToCalciteType(inputField.type),
        value: inputField.id,
        fieldType: inputField.fieldType,
        queryPath: inputField.queryPath,
        truncUnit: options?.truncUnit,
      };

      updateOrAddField(state, newFieldData, {
        ...options,
        clearAggregationOnUpdate: true,
      });
      cleanUpOrphanedSeries(state);

      if (
        channel === "h-facet" &&
        state.fields.find((f) => f.channel === "v-facet") != null &&
        state.chartConfig.facet?.columns != null
      ) {
        // special case
        // clear h-wrap so we don't immediately error on h drop
        // in the presence of v
        state.chartConfig.facet.columns = undefined;
      }
    },
    addChartSeriesField(
      state,
      {
        payload: {
          aggregation,
          channel,
          inputField,
          options,
          seriesId: seriesId_,
        },
      }: PayloadAction<{
        channel:
          | ChartExploreChannelOnePerSeries
          | ChartExploreChannelManyPerSeries;
        inputField: ExploreInputField;
        /** If not included, defaults to the first series */
        seriesId?: ExploreSeriesId;
        aggregation?: ExploreField["aggregation"] | null;
        options?: UpdateOrAddFieldOptions;
      }>,
    ) {
      addDefaultSeriesIfNone(state);
      const seriesId = seriesId_ ?? state.chartConfig.series[0]!.id;

      let resolvedAggregation;
      if (inputField.fieldType === ExploreFieldType.MEASURE) {
        resolvedAggregation = undefined;
      } else if (aggregation != null) {
        resolvedAggregation = aggregation;
        // eslint-disable-next-line eqeqeq -- explicit null check where null is intent to ignore default agg
      } else if (aggregation === null) {
        resolvedAggregation = undefined;
      } else {
        resolvedAggregation = defaultExploreAggregation(
          channel,
          inputField.type,
          inputField.fieldType,
        );
      }

      const newFieldData: ExploreField = {
        id: inputField.fieldId ?? ExploreFieldId.check(uuid()),
        seriesId,
        channel,
        title:
          inputField.fieldType !== "COLUMN" && inputField.id !== inputField.name
            ? inputField.name
            : undefined,
        dataType: columnTypeToCalciteType(inputField.type),
        value: inputField.id,
        aggregation: resolvedAggregation,
        fieldType: inputField.fieldType,
        queryPath: inputField.queryPath,
      };

      updateOrAddField(state, newFieldData, options);
      cleanUpOrphanedSeries(state);
    },
    addPivotField(
      state,
      {
        payload: { channel, dialect, inputField, options },
      }: PayloadAction<{
        inputField: ExploreInputField;
        channel: PivotExploreChannel;
        dialect: PushdownSqlDialect;
        options?: UpdateOrAddFieldOptions;
      }>,
    ) {
      addDefaultSeriesIfNone(state);
      const firstSeriesId = state.chartConfig.series[0]!.id;

      const pivotSection = `${channel}s` as const;
      const pivotValue = getPivotDropValue({
        item: { field: inputField.id },
        fieldType: inputField.type,
        section: pivotSection,
        dialect,
        config: exploreSpecToPivotConfig(state),
      });

      //TODO(EXPLORE) centralize these field-creation blocks
      const newFieldData: ExploreField = {
        id: inputField.fieldId ?? ExploreFieldId.check(uuid()),
        seriesId: firstSeriesId,
        channel,
        title:
          inputField.fieldType !== "COLUMN" && inputField.id !== inputField.name
            ? inputField.name
            : undefined,
        value: inputField.id,
        dataType: columnTypeToCalciteType(inputField.type),
        displayFormat: pivotValue.displayFormat,
        queryPath: inputField.queryPath,
        fieldType: inputField.fieldType,
      };

      if (PivotValueField.guard(pivotValue)) {
        //TODO(EXPLORE): EXP-1210 - our generated pivotValue will set a default
        //aggregation value, so we just ignore it for measures. We can remove
        //this one we update the pivot config to support measures
        newFieldData.aggregation =
          inputField.fieldType !== ExploreFieldType.MEASURE
            ? pivotAggToHqlAgg(pivotValue.aggregation)
            : undefined;
      }
      if (PivotGroupByField.guard(pivotValue)) {
        newFieldData.truncUnit = pivotTruncUnitToExploreTruncUnit(
          pivotValue.truncateTo,
        );
      }

      updateOrAddField(state, newFieldData, options);
      cleanUpOrphanedSeries(state);
    },
    removeField(
      state,
      { payload: { fieldId } }: PayloadAction<{ fieldId: ExploreFieldId }>,
    ) {
      state.fields = state.fields.filter((f) => f.id !== fieldId);
      onRemoveField(state);
      cleanUpOrphanedSeries(state);
    },
    removeMatchingFields(
      state,
      { payload: { value } }: PayloadAction<{ value: string }>,
    ) {
      state.fields = state.fields.filter(
        (f) => generateColumnIdForField(f) !== value,
      );

      onRemoveField(state);
      cleanUpOrphanedSeries(state);
    },
    removeAllMatchingFields(
      state,
      { payload: { values } }: PayloadAction<{ values: string[] }>,
    ) {
      state.fields = state.fields.filter(
        (f) => !values.includes(generateColumnIdForField(f)),
      );
      state.details.fields = state.details.fields.filter(
        (f) => !values.includes(generateColumnIdForField(f)),
      );

      onRemoveField(state);
      cleanUpOrphanedSeries(state);
    },

    replaceField(
      state,
      {
        payload: { fieldToReplaceId, newField, options },
      }: PayloadAction<{
        fieldToReplaceId: ExploreFieldId;
        newField: ExploreInputField;
        options?: UpdateOrAddFieldOptions;
      }>,
    ) {
      const oldField = state.fields.find((f) => f.id === fieldToReplaceId);
      if (!oldField) {
        return;
      }
      state.fields = state.fields.filter((f) => f.id !== fieldToReplaceId);

      // If we're dragging (moving) an already-active field to replace another active field,
      // delete the old one
      state.fields = state.fields.filter((f) => f.id !== newField.fieldId);

      const newFieldData: ExploreField = {
        id: ExploreFieldId.check(uuid()),
        seriesId: oldField.seriesId,
        channel: oldField.channel,
        title:
          newField.fieldType !== "COLUMN" && newField.id !== newField.name
            ? newField.name
            : undefined,
        value: newField.id,
        dataType: columnTypeToCalciteType(newField.type),
        displayFormat: oldField.displayFormat,
        queryPath: newField.queryPath,
        fieldType: newField.fieldType,
        truncUnit: options?.truncUnit,
      };

      newFieldData.aggregation =
        newField.fieldType !== ExploreFieldType.MEASURE &&
        getExploreSimpleType(newFieldData, state.visualizationType) ===
          getExploreSimpleType(oldField, state.visualizationType)
          ? oldField.aggregation
          : defaultExploreAggregation(
              oldField.channel,
              newField.type,
              newField.fieldType,
            );

      updateOrAddField(state, newFieldData);
      cleanUpOrphanedSeries(state);
    },
    updateCalcName(
      state,
      {
        payload: { currName, newName },
      }: PayloadAction<{
        currName: DisplayTableColumnId;
        newName?: DisplayTableColumnId;
      }>,
    ) {
      state.fields
        .filter((f) => f.value === currName)
        .forEach((existingField) => {
          const newFieldData = {
            ...existingField,
            value: newName ?? existingField.value,
          };
          updateOrAddField(state, newFieldData);
        });

      state.details.fields = state.details.fields.map((f) =>
        f.value === currName ? { ...f, value: newName ?? f.value } : f,
      );
      cleanUpOrphanedSeries(state);
    },
    /**
     * A simple update for field properties which can just be directly set by the user.
     * More complex transformations, like assigning a field to a new series,
     * should be handled by a method of their own.
     */
    setFieldProperty(
      state,
      {
        payload: { fieldId, updateData },
      }: PayloadAction<{
        fieldId: ExploreFieldId;
        updateData: Partial<ExploreFieldSimpleOptions>;
      }>,
    ) {
      const field = state.fields.find((f) => f.id === fieldId);
      if (field == null) {
        return;
      }
      Object.assign(field, updateData);
    },
    enableDetailFields(
      state,
      {
        payload: { baseTableFields },
      }: PayloadAction<{
        baseTableFields: SemanticAwareColumn[];
      }>,
    ) {
      state.details.enabled = true;
      if (state.details.fields.length === 0) {
        state.details.showAllBaseTableDetailFields = true;
        state.details.fields = baseTableFields.map(
          semanticAwareColumnToDetailField,
        );
      }
    },
    disableDetailFields(state, _: PayloadAction<void>) {
      state.details.enabled = false;
    },
    addDetailField(
      state,
      {
        payload: { allFieldsInGroup, field },
      }: PayloadAction<{
        field: SemanticAwareColumn;
        allFieldsInGroup: SemanticAwareColumn[];
      }>,
    ) {
      const isBaseTable = field.queryPath.length === 0;

      // if we are adding a base table field and we're supposed to be showing
      // all base table fields, but actually do not have any base table fields
      // add them all to the details table
      // handle this snowflake case
      if (
        isBaseTable &&
        state.details.showAllBaseTableDetailFields &&
        state.details.fields.length === 0 &&
        state.fields.length === 0
      ) {
        state.details.fields = allFieldsInGroup.map(
          semanticAwareColumnToDetailField,
        );
        state.details.enabled = true;
        return;
      }

      const existingDetailFieldIds = new Set(
        state.details.fields.map(generateColumnIdForField),
      );
      if (existingDetailFieldIds.has(generateColumnIdForField(field))) {
        return;
      }

      // adding field shows intent to see detail columns in table
      // so enable details table
      state.details.enabled = true;
      state.details.fields.push(semanticAwareColumnToDetailField(field));

      if (isBaseTable) {
        state.details.showAllBaseTableDetailFields =
          detailFieldsHaveAllBaseTableFields(
            state.details.fields,
            allFieldsInGroup,
          );
      }
    },
    removeDetailField(
      state,
      {
        payload: { baseTableFields, field },
      }: PayloadAction<{
        field: SemanticAwareColumn;
        /**
         * Base table fields *must* be provided in the event that a single
         * field is removed when `showAllBaseTableDetailFields` is true.
         */
        baseTableFields: SemanticAwareColumn[];
      }>,
    ) {
      if (
        state.details.showAllBaseTableDetailFields &&
        state.details.fields.length === 0
      ) {
        state.details.fields = baseTableFields.map(
          semanticAwareColumnToDetailField,
        );
      }

      const removedFieldId = generateColumnIdForField(field);
      state.details.fields = state.details.fields.filter(
        (f) => generateColumnIdForField(f) !== removedFieldId,
      );
      state.details.showAllBaseTableDetailFields =
        detailFieldsHaveAllBaseTableFields(
          state.details.fields,
          baseTableFields,
        );
    },
    removeCalc(state, payloadAction: PayloadAction<{ value: string }>) {
      const {
        payload: { value },
      } = payloadAction;
      state.fields = state.fields.filter(
        (f) => generateColumnIdForField(f) !== value,
      );
      state.details.fields = state.details.fields.filter(
        (f) => generateColumnIdForField(f) !== value,
      );

      onRemoveField(state);
      cleanUpOrphanedSeries(state);
    },
    // used for settting base table fields without enabling details
    setBaseTableDetailFields(
      state,
      {
        payload: { baseTableFields },
      }: PayloadAction<{ baseTableFields: SemanticAwareColumn[] }>,
    ) {
      state.details.fields = baseTableFields.map(
        semanticAwareColumnToDetailField,
      );
    },
    showAllBaseTableDetailFields(
      state,
      {
        payload: { baseTableFields },
      }: PayloadAction<{ baseTableFields: SemanticAwareColumn[] }>,
    ) {
      const existingDetailFieldIds = new Set(
        state.details.fields.map(generateColumnIdForField),
      );
      for (const f of baseTableFields) {
        if (!existingDetailFieldIds.has(generateColumnIdForField(f))) {
          state.details.fields.push(semanticAwareColumnToDetailField(f));
        }
      }

      state.details.enabled = true;
      state.details.showAllBaseTableDetailFields = true;
    },
    removeAllDetailFields(state) {
      state.details.fields = [];
      state.details.showAllBaseTableDetailFields = false;
    },
    clearFields(state) {
      state.fields = [];
      onRemoveField(state);
      cleanUpOrphanedSeries(state);

      // reset details to default state
      state.details.fields = [];
      state.details.showAllBaseTableDetailFields = true;
      state.details.enabled = true;
    },
    setViewType(state, { payload: viewType }: PayloadAction<ExploreViewType>) {
      state.viewType = viewType;
    },
    setVizType(
      state,
      {
        payload: vizType,
      }: PayloadAction<ChartTypeSelectorOption | "pivot-table">,
    ) {
      if (vizType === "pivot-table") {
        state.visualizationType = "pivot-table";
        state.fields = mapExploreFieldsToPivotFields(state.fields);
        addDefaultSeriesIfNone(state);
        state.chartConfig.series = [state.chartConfig.series[0]!];
        const firstSeriesId = state.chartConfig.series[0]!.id;
        state.chartConfig.seriesGroups = [[firstSeriesId]];
        state.fields.forEach((f) => (f.seriesId = firstSeriesId));
      } else {
        const previousVisType = state.visualizationType;
        state.visualizationType = "chart";
        if (state.chartConfig.series.length === 1) {
          // this reducer should not be called in multi-series mode
          const firstSeries = state.chartConfig.series[0]!;
          const previousType =
            previousVisType !== "chart" ? undefined : firstSeries.type;
          state.chartConfig.orientation = "vertical"; // default, overridden for bars
          switch (vizType) {
            case "column_grouped":
              firstSeries.type = "bar";
              firstSeries.normalize = undefined;
              firstSeries.barGrouped = true;
              state.chartConfig.orientation = "vertical";
              break;
            case "bar_grouped":
              firstSeries.type = "bar";
              firstSeries.barGrouped = true;
              firstSeries.normalize = undefined;
              state.chartConfig.orientation = "horizontal";
              break;
            case "column_stacked":
              firstSeries.type = "bar";
              firstSeries.barGrouped = false;
              firstSeries.normalize = undefined;
              state.chartConfig.orientation = "vertical";
              break;
            case "bar_stacked":
              firstSeries.type = "bar";
              firstSeries.barGrouped = false;
              firstSeries.normalize = undefined;
              state.chartConfig.orientation = "horizontal";
              break;
            case "column_stacked100":
              firstSeries.type = "bar";
              firstSeries.barGrouped = false;
              firstSeries.normalize = "base-axis";
              state.chartConfig.orientation = "vertical";
              break;
            case "bar_stacked100":
              firstSeries.type = "bar";
              firstSeries.barGrouped = false;
              firstSeries.normalize = "base-axis";
              state.chartConfig.orientation = "horizontal";
              break;
            case "area_stacked":
              firstSeries.type = "area";
              firstSeries.normalize = undefined;
              break;
            case "area_stacked100":
              firstSeries.type = "area";
              firstSeries.normalize = "base-axis";
              break;
            case "histogram":
              firstSeries.type = "histogram";
              break;
            case "line":
              firstSeries.type = "line";
              break;
            case "scatter":
              firstSeries.type = "scatter";
              break;
            case "pie":
              firstSeries.type = "pie";
              break;
            default:
              assertNever(vizType, vizType);
          }
          const newFields = mapExploreFieldsToChartFields(state, previousType);
          if (
            newFields.find((f) => f.channel === "h-facet") &&
            newFields.find((f) => f.channel === "v-facet") &&
            state.chartConfig.facet?.columns != null
          ) {
            //special case: the mapper gave us h/v so we cannot wrap h
            state.chartConfig.facet.columns = undefined;
          }
          state.fields = newFields;
        }
      }
      // note: do not call cleanUpOrphanedSeries() here, as we either do it
      // in the pivot branch above, else we only operate on single series

      // if looking at table only, switch to both so that the user
      // can see the visualization as well
      if (state.viewType === "source-table") {
        state.viewType = "both";
      }
    },
    setChartSpec(
      state,
      {
        payload: { fieldGroups, newChartSpec, oldChartSpec },
      }: PayloadAction<{
        oldChartSpec: ChartSpec;
        newChartSpec: ChartSpec;
        fieldGroups: SemanticAwareFieldGroup[] | undefined;
      }>,
    ) {
      // note: do not call cleanUpOrphanedSeries() here, as this function does
      // its own series-management
      return updateExploreSpecFromChartDiffs({
        curSpec: oldChartSpec,
        newSpec: newChartSpec,
        // `updateExploreSpecFromChartDiffs` clones the spec,
        // so we just pass it the original object instead of the immer object
        exploreSpec: original(state)!,
        fieldGroups,
      });
    },
    addUserJoin(
      state,
      { payload: { join, tables } }: PayloadAction<ExploreAddUserJoinPayload>,
    ) {
      state.joins ??= [];
      state.joins.push(join);

      // Any table with the same name that was previously configured is replaced by the new data.
      const tableNames = new Set(tables.map((t) => t.name));
      state.tables ??= [];
      state.tables = state.tables
        .filter((t) => !tableNames.has(t.name))
        .concat(tables);
    },
    editUserJoin(
      state,
      {
        payload: {
          basePrimaryKey,
          joinToEdit,
          relationshipType,
          sourceTableJoinColumn,
          targetPrimaryKey,
          targetTableJoinColumn,
        },
      }: PayloadAction<ExploreEditUserJoinPayload>,
    ) {
      const joinToEditIdx = state.joins?.findIndex((j) =>
        isEqual(original(j), joinToEdit),
      );
      if (
        joinToEditIdx == null ||
        state.joins == null ||
        state.tables == null
      ) {
        throw new Error("Join to edit not found");
      }
      const sourceTable = state.tables.find(
        (t) => t.name === joinToEdit.sourceTable.name,
      );
      const targetTable = state.tables.find(
        (t) => t.name === joinToEdit.targetTable.name,
      );
      if (sourceTable == null || targetTable == null) {
        throw new Error("Source or target table not found");
      }
      state.joins[joinToEditIdx] = {
        ...joinToEdit,
        sourceTableJoinColumn,
        targetTableJoinColumn,
        relationshipType,
      };
      sourceTable.primaryKeyColumn = basePrimaryKey;
      targetTable.primaryKeyColumn = targetPrimaryKey;
    },
    removeUserJoin(
      state,
      {
        payload: { baseTableName, targetTableName },
      }: PayloadAction<{
        baseTableName: SemanticDatasetName;
        targetTableName: SemanticDatasetName;
      }>,
    ) {
      if (state.joins == null) return;

      // Make set of faux semantic datasets so we can use our semantic graph helpers
      const datasets: { [name: string]: SemanticDatasetStub } = {};
      for (const join of state.joins) {
        const {
          sourceTable: { name: base },
          targetTable: { name: target },
        } = join;
        datasets[base] ??= {
          name: base,
          properties: { joins: [] },
        };
        datasets[base].properties.joins!.push({ target });
      }

      // Get all downstream joins that we need to remove in addition to orignal one
      const joinsToRemove = getJoinsToDelete(Object.values(datasets), [
        baseTableName,
        targetTableName,
      ]);

      // Remove all those joins
      state.joins = state.joins.filter((j) => {
        const base = j.sourceTable.name;
        const target = j.targetTable.name;
        return joinsToRemove[base] == null || !joinsToRemove[base].has(target);
      });

      // Remove any tables that are no longer included in the graph at all
      const remainingTables = new Set(
        state.joins.flatMap((j) => [j.sourceTable.name, j.targetTable.name]),
      );
      if (state.tables == null) return;
      state.tables = state.tables.filter((t) => remainingTables.has(t.name));

      // Only keep fields that reference still remaining tables
      state.fields = state.fields.filter(
        (f) =>
          f.queryPath.length === 0 ||
          f.queryPath.every((t) => remainingTables.has(t)),
      );
      state.details.fields = state.details.fields.filter(
        (f) =>
          f.queryPath.length === 0 ||
          f.queryPath.every((t) => remainingTables.has(t)),
      );
      cleanUpOrphanedSeries(state);
    },
    removeAllUserJoins(state) {
      const hasJoins = (state.joins ?? []).length > 0;
      const hasTables = (state.tables ?? []).length > 0;

      if (hasJoins || hasTables) {
        state.joins = [];
        state.tables = [];

        // Remove any fields which reference anything other than the base table
        // (This won't work correctly once we allow user-defined joins on semantic projects)
        state.fields = state.fields.filter((f) => f.queryPath.length === 0);
        state.details.fields = state.details.fields.filter(
          (f) => f.queryPath.length === 0,
        );
        cleanUpOrphanedSeries(state);
      }
    },
    setChartConfigProperty<
      // exclude series-related properties as changes to these properties must be made in tandem
      // with other explore fields
      K extends keyof Exclude<ExploreChartConfig, "series" | "seriesGroups">,
    >(
      state: Draft<ExploreSpec>,
      {
        payload: { key, value },
      }: PayloadAction<{
        key: K;
        value: ExploreChartConfig[K];
      }>,
    ) {
      state.chartConfig[key] = value;
    },

    updateValuesAsRows(
      state,
      { payload: { valuesAsRows } }: PayloadAction<{ valuesAsRows: boolean }>,
    ) {
      state.valuesAsRows = valuesAsRows;
    },
  },
});

// #endregion

// #region state modication helpers

export function getAutoAddFieldDestination(
  spec: Pick<ExploreSpec, "chartConfig" | "fields" | "visualizationType">,
  inputField: ExploreInputField,
): {
  channel: ExploreChannel;
  aggregation: "Sum" | undefined;
} {
  const firstSeries = spec.chartConfig.series[0];
  const firstSeriesChannels = spec.fields
    .filter((f) => f.seriesId === firstSeries!.id)
    .map((f) => f.channel);

  const { fieldType } = inputField;
  const numericCol = fieldType !== "MEASURE" && inputField.type === "NUMBER";
  if (
    spec.visualizationType === "chart" &&
    isHistogram(spec.chartConfig) &&
    numericCol &&
    !firstSeriesChannels.includes("base-axis")
  ) {
    return {
      channel: "base-axis",
      aggregation: undefined,
    };
  }

  const aggregation: "Sum" | undefined = numericCol ? "Sum" : undefined;
  const isMeasure = fieldType === "MEASURE" || aggregation != null;

  const findAvailableChannel = (
    candidates: ExploreChannel[],
  ): ExploreChannel => {
    for (const candidate of candidates) {
      if (
        !firstSeriesChannels.includes(candidate) &&
        maybeInvalidMappingReason({
          spec,
          channel: candidate,
          incomingType: dataFrameHeaderTypeToChartDataType(inputField.type),
          isAggregated: isMeasure,
        }) == null
      ) {
        return candidate;
      }
    }
    return "tooltip";
  };

  const channel = isMeasure
    ? findAvailableChannel(["value", "cross-axis", "color"])
    : findAvailableChannel(["row", "base-axis", "color", "h-facet", "v-facet"]);

  return {
    channel,
    aggregation,
  };
}

function addDefaultSeriesIfNone(state: Draft<ExploreSpec>): void {
  if (state.chartConfig.series.length === 0) {
    const newSeries = defaultExploreChartSeries();
    state.chartConfig.series.push(newSeries);
    state.chartConfig.seriesGroups = [[newSeries.id]];
  }
}

function onRemoveField(state: Draft<ExploreSpec>): void {
  if (state.fields.length === 0) {
    state.details.enabled = true;
    if (state.details.fields.length === 0) {
      state.details.showAllBaseTableDetailFields = true;
    }
  }
}

function updateOrAddField(
  state: Draft<ExploreSpec>,
  field: ExploreField,
  options?: UpdateOrAddFieldOptions,
): void {
  const addToFront = options?.addToFront ?? false;
  const existingFieldIdx = state.fields.findIndex((f) => f.id === field.id);

  if (existingFieldIdx !== -1) {
    state.fields[existingFieldIdx] = {
      ...state.fields[existingFieldIdx],
      // When a field is moved e.g. from cross-axis to base-axis, sometimes the aggregation sticks around
      ...(options?.clearAggregationOnUpdate ? { aggregation: undefined } : {}),
      ...field,
    };
  } else {
    if (state.fields.length === 0) {
      // When adding the first agg/group by field, we hide all detail fields
      state.details.enabled = false;
    }
    if (
      calciteTypeisDatetimeType(field.dataType) &&
      truncationChannels.has(field.channel)
    ) {
      field.truncUnit = field.truncUnit ?? "day";
    }

    if (
      calciteTypeisNumericType(field.dataType) &&
      (field.channel === "h-facet" || field.channel === "v-facet")
    ) {
      field.bin = field.bin ?? { count: 6 };
    }

    if (addToFront) {
      state.fields.unshift(field);
    } else {
      state.fields.push(field);
    }
  }
}

/**
 * If an existing field is moved or removed,
 * it's possible that there are now orphaned series.
 * Remove these if necessary.
 */
function cleanUpOrphanedSeries(state: Draft<ExploreSpec>): void {
  addDefaultSeriesIfNone(state);
  const origFirstSeries = state.chartConfig.series[0]!;

  // Make a set of all series without specific channels mapped to them
  const orphanedSeriesIds = new Set(state.chartConfig.series.map((s) => s.id));
  for (const field of state.fields) {
    if (
      ChartExploreChannelOnePerSeries.guard(field.channel) ||
      ChartExploreChannelManyPerSeries.guard(field.channel)
    ) {
      orphanedSeriesIds.delete(field.seriesId);
    }
  }

  // Remove those series
  //TODO(EXPLORE) we need to look at this function more closely...
  // series without fields aren't bugs, they're necessary for charts that are
  // in the process of being constructed
  state.chartConfig.series = state.chartConfig.series.filter(
    (s) => !orphanedSeriesIds.has(s.id),
  );

  // If we removed all the series, bring back the first one so we have something
  if (state.chartConfig.series.length === 0) {
    state.chartConfig.series.push(origFirstSeries);
    orphanedSeriesIds.delete(origFirstSeries.id);
  }

  // If top-level fields were assigned to a removed series, reassign them to the new first series
  if (orphanedSeriesIds.has(origFirstSeries.id)) {
    const newFirstSeriesId = state.chartConfig.series[0]!.id;
    for (const field of state.fields) {
      if (field.seriesId === origFirstSeries.id) {
        field.seriesId = newFirstSeriesId;
      }
    }
  }

  // Removed series need to be removed from the series group they were in
  // And then any empty groups should also be removed
  for (let i = 0; i < state.chartConfig.seriesGroups.length; i++) {
    const group = state.chartConfig.seriesGroups[i]!;
    state.chartConfig.seriesGroups[i] = group.filter((id) =>
      state.chartConfig.series.some((s) => s.id === id),
    );
  }
  state.chartConfig.seriesGroups = state.chartConfig.seriesGroups.filter(
    (group) => group.length > 0,
  );
}

function semanticAwareColumnToDetailField(
  column: SemanticAwareColumn,
): ExploreDetailField {
  return {
    value: column.columnId,
    queryPath: column.queryPath,
    dataType: columnTypeToCalciteType(column.columnType),
    fieldType: column.fieldType,
  };
}

function detailFieldsHaveAllBaseTableFields(
  detailFields: ExploreDetailField[],
  allFieldsInGroup: SemanticAwareColumn[],
): boolean {
  const existingDetailFieldIds = new Set(
    detailFields.map(generateColumnIdForField),
  );
  return allFieldsInGroup.every((f) =>
    existingDetailFieldIds.has(generateColumnIdForField(f)),
  );
}

// #endregion

// #region exports for external use
export type ExploreAddUserJoinPayload = {
  join: ExploreUserJoin;
  tables: ExploreUserJoinTable[];
};

export type ExploreEditUserJoinPayload = {
  basePrimaryKey: ExploreUserJoinColumn;
  targetPrimaryKey: ExploreUserJoinColumn;
  sourceTableJoinColumn: ExploreUserJoinColumn;
  targetTableJoinColumn: ExploreUserJoinColumn;
  relationshipType: ExploreUserJoin["relationshipType"];
  joinToEdit: ExploreUserJoin;
};

export interface UpdateOrAddFieldOptions {
  addToFront?: boolean;
  clearAggregationOnUpdate?: boolean;
  truncUnit?: ExploreTimeUnit;
}

export const { actions: exploreSpecActions, reducer: exploreSpecReducer } =
  exploreSpecSlice;

type SliceActions<T extends { [k: string]: (...args: any[]) => unknown }> = {
  [k in keyof T]: ReturnType<T[k]>;
}[keyof T];
export type ExploreSpecUpdateActions = SliceActions<typeof exploreSpecActions>;

// #endregion

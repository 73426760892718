import { Literal, Static, Union } from "runtypes";

import { DisplayTableColumnOutputType } from "../display-table/outputTypes.js";
import { ExploreField, HexSLTypes } from "../index.js";
import { HqlTruncUnitLiteral } from "../hql/types.js";
import {
  ExploreCellDataframe,
  getDataframeName,
} from "../sql/dataSourceTableConfig.js";

export function getQueryModeDisabledDataframeName(
  dataframe: ExploreCellDataframe,
): string {
  return `${getDataframeName(dataframe)}_df`;
}

export const HexSLTypeMapping: Record<
  NonNullable<HexSLTypes.DimensionType>,
  DisplayTableColumnOutputType
> = {
  ["boolean"]: DisplayTableColumnOutputType.BOOLEAN,
  ["date"]: DisplayTableColumnOutputType.DATE,
  ["null"]: DisplayTableColumnOutputType.UNKNOWN,
  ["number"]: DisplayTableColumnOutputType.NUMBER,
  ["other"]: DisplayTableColumnOutputType.UNKNOWN,
  ["string"]: DisplayTableColumnOutputType.STRING,
  ["time"]: DisplayTableColumnOutputType.TIME,
  ["timestamp"]: DisplayTableColumnOutputType.DATETIME,
  ["timestamptz"]: DisplayTableColumnOutputType.DATETIMETZ,
};

// in hex-sl, these measure agg types all produce numeric results
export const NumericMeasureAggTypes = Union(
  Literal("count"),
  Literal("countdistinct"),
  Literal("sum"),
  Literal("avg"),
  Literal("min"),
  Literal("max"),
  Literal("median"),
  Literal("stddev"),
  Literal("stddevpop"),
  Literal("variance"),
  Literal("variancepop"),
  Literal("sumboolean"),
);

export type NumericMeasureAggTypes = Static<typeof NumericMeasureAggTypes>;

export function exploreFieldIsDateTimeType(field: ExploreField): boolean {
  return (
    field.dataType === "DATE" ||
    field.dataType === "TIMESTAMP" ||
    field.dataType === "TIMESTAMPTZ"
  );
}

export const ExploreTruncationOptions = HqlTruncUnitLiteral.alternatives
  .map(({ value }) => value)
  .filter((value) => value !== "dayofweek");

import { calciteTypeToChartDataType } from "../chart/hql/chartHqlUtils.js";
import { ChartDataType } from "../chart/types.js";
import { HqlAggregationFunction } from "../hql/types.js";

import { getFieldActiveScaleType } from "./exploreFieldUtils.js";
import { ExploreField, ExploreVisualizationType } from "./types.js";

export function getExploreSimpleType(
  field: ExploreField,
  vizType: ExploreVisualizationType,
): ChartDataType {
  if (vizType === "pivot-table") {
    return calciteTypeToChartDataType(field.dataType);
  }
  return getFieldActiveScaleType(field);
}

export const exploreStringAggregations: HqlAggregationFunction[] = [
  "Count",
  "CountDistinct",
];
export const exploreDatetimeAggregations: HqlAggregationFunction[] = [
  "Count",
  "CountDistinct",
  "Median",
  "Min",
  "Max",
];
export const exploreNumberAggregations: HqlAggregationFunction[] = [
  "Sum",
  "Count",
  "Avg",
  "Min",
  "Max",
  "CountDistinct",
  "Median",
  "StdDev",
  "StdDevPop",
  "Variance",
  "VariancePop",
];

import { Literal, Record, Static, String } from "runtypes";

// in separate file to avoid circular dependency
export const ExploreCustomTimeUnit = Record({
  // for now this is only custom time unit type, in the future we may have
  // offset time units, and it could be useful to distinguish this in the
  // explore spec
  type: Literal("timespine"),
  name: String,
});

export type ExploreCustomTimeUnit = Static<typeof ExploreCustomTimeUnit>;
